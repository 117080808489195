import React, { Fragment, useState } from "react";
import Header from "./header";
import { isEmpty } from "lodash";
import CustomLoader from "../common/CustomLoader";
import AnalysisChart from "../charts/AnalysisChart";
import Dropdown from "../common/Dropdown";

interface IProps {
  parsedCsvFiles: any;
  csvFields: string[];
  isModalOpen: boolean;
  isAnalyzing: boolean;
}

const Visualization: React.FC<IProps> = ({
  parsedCsvFiles,
  csvFields,
  isModalOpen,
  isAnalyzing,
}) => {
  if (isEmpty(parsedCsvFiles) || isEmpty(csvFields) || !isModalOpen || isAnalyzing) {
    return (
      <div className="relative graph-container" id={"graph-container"}>
        <Header text="Visualization" />
        {isAnalyzing && (
          <div className="absolute width-full height-full full-flex opacity-4">
            <CustomLoader isFixed={false} />
          </div>
        )}
      </div>
    );
  }

  const initialFields = csvFields.slice(1); // Exclude the first field
  const [selectedFields, setSelectedFields] = useState<string[]>(initialFields);

  const handleFieldChange = (index: number, value: string) => {
    const updatedFields = [...selectedFields];
    updatedFields[index] = value;
    setSelectedFields(updatedFields);
  };

  return (
    <div className="relative graph-container" id={"graph-container"}>
      <Header text="Visualization" />
      <Fragment>
        {selectedFields.map((field, index) => {
          const dropdownOptions = csvFields.slice(1).map((item) => ({ value: item, label: item }));

          return (
            <div key={index} style={{ marginBottom: "20px", textAlign: "center" }}>
              <Dropdown
                options={dropdownOptions}
                value={field}
                onChange={(value) => handleFieldChange(index, value)}
              />
              <AnalysisChart
                key={field}
                chartData={parsedCsvFiles[0].map((row: any, i: number) => ({
                  x: i,
                  y: parseFloat(row[field]),
                }))}
                keyName={field}
                label={field}
                selectedField={field}
              />
            </div>
          );
        })}
      </Fragment>
    </div>
  );
};

export default Visualization;
import React, { useState, useRef, useEffect } from "react";
import Highcharts, { Options as HighchartsOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import AnnotationsModule from "highcharts/modules/annotations";

if (typeof AnnotationsModule === "function") {
  AnnotationsModule(Highcharts);
}

interface AnalysisChartProps {
  chartData: { x: number; y: number }[];
  keyName: string;
  label?: string;
  selectedField?: string;
}

const AnalysisChart: React.FC<AnalysisChartProps> = ({ chartData, keyName, label,selectedField }) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const calculateStats = (data: { x: number; y: number }[]) => {
    const yValues = data.map((point) => point.y);
    const modeMap = yValues.reduce(
      (acc, val) => acc.set(val, (acc.get(val) || 0) + 1),
      new Map<number, number>()
    );
    const mode = Array.from(modeMap.entries()).reduce((a, b) => (a[1] > b[1] ? a : b))[0];
    return {
      min: Math.min(...yValues),
      max: Math.max(...yValues),
      average: yValues.reduce((sum, val) => sum + val, 0) / yValues.length,
      median: yValues.sort((a, b) => a - b)[Math.floor(yValues.length / 2)],
      mode,
    };
  };

  const defaultStats = calculateStats(chartData);

  const defaultAnnotations: Highcharts.AnnotationsOptions[] = [
    {
      labels: [
        {
          point: {
            x: chartData[Math.floor(chartData.length / 2)].x,
            y: defaultStats.max,
            xAxis: 0,
            yAxis: 0,
          },
          text: `
            <b>Min:</b> ${defaultStats.min}<br/>
            <b>Max:</b> ${defaultStats.max}<br/>
            <b>Avg:</b> ${defaultStats.average.toFixed(2)}<br/>
            <b>Median:</b> ${defaultStats.median}<br/>
            <b>Mode:</b> ${defaultStats.mode}
          `,
          style: {
            fontSize: "12px",
            color: "#fff",
            backgroundColor: "#000",
            padding: "5px",
          },
        },
      ],
    },
  ];

  const [chartOptions, setChartOptions] = useState<HighchartsOptions>({
    chart: {
      type: "line",
      zooming: {
        type: "x",
      },
    },
    title: {
      text: label || "Visualization Chart",
    },
    xAxis: {
      categories: chartData.map((point) => point.x.toString()),
      title: { text: "Time" },
    },
    yAxis: {
      title: { text: selectedField || keyName || "Value" },
    },
    series: [
      {
        type: "line",
        name: label || keyName || "Series",
        data: chartData.map((point) => point.y),
      },
    ],
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false,
    },
    annotations: defaultAnnotations,
  });

  useEffect(() => {
    const newStats = calculateStats(chartData);
    const newAnnotations: Highcharts.AnnotationsOptions[] = [
      {
        labels: [
          {
            point: {
              x: chartData[Math.floor(chartData.length / 2)].x,
              y: newStats.max,
              xAxis: 0,
              yAxis: 0,
            },
            text: `
              <b>Min:</b> ${newStats.min}<br/>
              <b>Max:</b> ${newStats.max}<br/>
              <b>Avg:</b> ${newStats.average.toFixed(2)}<br/>
              <b>Median:</b> ${newStats.median}<br/>
              <b>Mode:</b> ${newStats.mode}
            `,
            style: {
              fontSize: "12px",
              color: "#fff",
              backgroundColor: "#000",
              padding: "5px",
            },
          },
        ],
      },
    ];

    setChartOptions((prev) => ({
      ...prev,
      yAxis: {
        title: { text: selectedField }, 
      },
      series: [
        {
          type: "line",
          name: label || keyName || "Series",
          data: chartData.map((point) => point.y),
        },
      ],
      annotations: newAnnotations,
    }));

    chartRef.current?.chart?.zoomOut();
  }, [chartData, label, keyName, selectedField]);

  const handleSelection = (e: any) => {
    if (!e.xAxis || !e.xAxis[0]) return;
    const min = Math.floor(e.xAxis[0].min as number);
    const max = Math.ceil(e.xAxis[0].max as number);
    const selectedData = chartData.filter(
      (point) => point.x >= min && point.x <= max
    );
    const stats = calculateStats(selectedData);

    const zoomAnnotations = [
      {
        labels: [
          {
            point: {
              x: (min + max) / 2,
              y: stats.max,
              xAxis: 0,
              yAxis: 0,
            },
            text: `
              <b>Min:</b> ${stats.min}<br/>
              <b>Max:</b> ${stats.max}<br/>
              <b>Avg:</b> ${stats.average.toFixed(2)}<br/>
              <b>Median:</b> ${stats.median}<br/>
              <b>Mode:</b> ${stats.mode}
            `,
          },
        ],
      },
    ];

    setChartOptions((prev) => ({
      ...prev,
      annotations: zoomAnnotations,
    }));
  };

  const handleResetZoom = () => {
    setChartOptions((prev) => ({
      ...prev,
      annotations: defaultAnnotations,
    }));
    chartRef.current?.chart?.zoomOut();
  };

  const handleMouseWheel = (event: WheelEvent) => {
    event.preventDefault();
    const chart = chartRef.current?.chart;
    if (!chart) return;

    const xAxis = chart.xAxis[0];
    const extremes = xAxis.getExtremes();
    const range = extremes.max - extremes.min;
    const zoomFactor = 0.1;

    let newMin, newMax;
    if (event.deltaY < 0) {
      newMin = extremes.min + range * zoomFactor;
      newMax = extremes.max - range * zoomFactor;
    } else {
      newMin = extremes.min - range * zoomFactor;
      newMax = extremes.max + range * zoomFactor;
    }

    xAxis.setExtremes(
      Math.max(extremes.dataMin, newMin),
      Math.min(extremes.dataMax, newMax)
    );
  };

  useEffect(() => {
    const chartContainer = chartRef.current?.chart.container;
    if (chartContainer) {
      chartContainer.addEventListener("wheel", handleMouseWheel);
    }

    return () => {
      if (chartContainer) {
        chartContainer.removeEventListener("wheel", handleMouseWheel);
      }
    };
  }, []);

  return (
    <div style={{ margin: "20px 0" }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          ...chartOptions,
          chart: {
            ...chartOptions.chart,
            events: {
              selection: handleSelection,
            },
          },
        }}
        ref={chartRef}
      />
      <button
        onClick={handleResetZoom}
        style={{
          marginTop: "10px",
          padding: "10px",
          backgroundColor: "#ff0000",
          color: "#fff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "14px",
        }}
      >
        Reset Analytics
      </button>
    </div>
  );
};

export default AnalysisChart;